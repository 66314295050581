
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.transparent-to-white-gradient {
  background: linear-gradient(to right,  rgba(255, 255, 255, 0.5), rgb(255, 255, 255));
}

.apexcharts-datalabel {
  font-size: 15px;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animated.fadeIn {
  animation-name: fadeIn;
  animation-duration: 0.5s;
  animation-fill-mode: both;
}

/* Fade In Down */
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animated.fadeInDown {
  animation-name: fadeInDown;
  animation-duration: 0.5s;
  animation-fill-mode: both;
}

/* Fade In Up */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animated.fadeInUp {
  animation-name: fadeInUp;
  animation-duration: 0.5s;
  animation-fill-mode: both;
}

/* Fade In Left */
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-1000px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.animated.fadeInLeft {
  animation-name: fadeInLeft;
  animation-duration: 0.8s;
  animation-fill-mode: both;
}

/* Fade In Right */
@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.animated.fadeInRight {
  animation-name: fadeInRight;
  animation-duration: 0.5s;
  animation-fill-mode: both;
}

/* Zoom In */
@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.animated.zoomIn {
  animation-name: zoomIn;
  animation-duration: 0.5s;
  animation-fill-mode: both;
}

/* Bounce */
@keyframes bounce {
  from,
  20%,
  53%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }
  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    transform: translate3d(0, -4px, 0);
  }
}

.animated.bounce {
  animation-name: bounce;
  animation-duration: 0.75s;
  animation-fill-mode: both;
}

@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
    visibility: visible;
  }

  to {
    transform: translateX(0);
  }
}

.animated.slideInLeft {
  animation-name: slideInLeft;
  animation-duration: 0.5s;
  animation-fill-mode: both;
}

@media (max-width: 992px) {
  .sidebar-animation {
    animation-name: slideInLeft;
    animation-duration: 0.5s;
    animation-fill-mode: both;
  }
}

.arrow_box {
  position: relative;
  background: #88b7d5;
  border: 4px solid #c2e1f5;
}
.arrow_box:after, .arrow_box:before {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.arrow_box:after {
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: #88b7d5;
  border-width: 30px;
  margin-left: -30px;
}
.arrow_box:before {
  border-color: rgba(194, 225, 245, 0);
  border-bottom-color: #c2e1f5;
  border-width: 36px;
  margin-left: -36px;
}

.hover-grow {
  transition: all .2s ease-in-out;
}

.hover-grow:hover {
  transform: scale(1.05);
}

.leaflet-container {
  height: 100%;
  z-index: 100!important;
}

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height:100%
}

.loader {
    color: #000;
    font-size: 20px;
    margin: 100px auto;
    width: 80px;
    height: 80px;
    border: 16px solid #f3f3f3;
    border-top: 16px solid #009ef7;
    border-radius: 50%;
    animation: spin 1s linear infinite ;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

